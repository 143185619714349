import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// icons
import { FaPhoneAlt, FaClock, FaWhatsappSquare } from "react-icons/fa";
import { MdEmail, MdEngineering, MdFoundation, MdGTranslate } from "react-icons/md";
import { GrCertificate } from "react-icons/gr";
import { LuCalendarClock } from "react-icons/lu";
import { FaPeopleGroup, FaSquareInstagram, FaLocationDot } from "react-icons/fa6";
import { FaCode, FaLinkedin, FaTiktok, FaRoad, FaWater, FaStamp, FaWaveSquare, FaSwimmingPool, FaFacebookSquare } from 'react-icons/fa';
import { GiBrickWall, GiBroom, GiHomeGarage, GiFloorPolisher } from "react-icons/gi";
import { PiStepsDuotone, PiFingerprintSimpleFill, PiPark, PiGarageFill } from "react-icons/pi";
import { CiMenuKebab } from "react-icons/ci";
// images
import logo from "./multimedia/images/logo.png";
import slider_img from "./multimedia/images/driveway1.jpeg";
import slider_img2 from "./multimedia/images/pathways1.jpeg";
import slider_img3 from "./multimedia/images/patio3.jpg";
import service_img1 from "./multimedia/images/service_img1.jpg";
import service_img2 from "./multimedia/images/service_img2.jpg";
import service_img3 from "./multimedia/images/service_img3.jpg";
import portfolio1 from "./multimedia/images/concretefoundation1.jpeg";
import portfolio2 from "./multimedia/images/concretefoundation2.jpeg";
import portfolio3 from "./multimedia/images/concretefoundation3.jpeg";
import portfolio4 from "./multimedia/images/concretefoundation4.jpg";
import portfolio16 from "./multimedia/images/concretefoundation7.jpeg";
import portfolio5 from "./multimedia/images/driveway1.jpeg";
import portfolio6 from "./multimedia/images/driveway2.jpeg";
import portfolio7 from "./multimedia/images/driveway3.jpeg";
import portfolio8 from "./multimedia/images/sidewalk1.jpeg";
import portfolio9 from "./multimedia/images/sidewalk3.jpg";
import portfolio10 from "./multimedia/images/steps1.jpg";
import portfolio11 from "./multimedia/images/steps2.jpg";
import portfolio12 from "./multimedia/images/steps3.jpg";
import portfolio13 from "./multimedia/images/patio2.jpeg";
import portfolio14 from "./multimedia/images/patio3.jpg";
import portfolio15 from "./multimedia/images/patio4.jpeg";
import testimonial1 from "./multimedia/images/client1.jpg";
import testimonial2 from "./multimedia/images/client2.jpg";
import testimonial3 from "./multimedia/images/client3.jpg";
import man from "./multimedia/images/man.png";
// styles
import './styles/custom.css'
import './styles/bootsnav.css'
import './styles/jquery.fancybox.css'
import 'react-tabs/style/react-tabs.css';
// langueages
import englishContent from './languages/english';
import spanishContent from './languages/spanish';

function App() {
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState('english');
    const content = language === 'english' ? englishContent : spanishContent;

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 1000);

        return () => clearTimeout(timer); 
    }, []);

    const handleChangeLanguage = (e) => {
        setLanguage(e.target.value);
    };
  if (loading) {
    return (
        <div id="loading">
            <div id="loading-center">
                <div id="loading-center-absolute">
                    <div className="object"></div>
                    <div className="object"></div>
                    <div className="object"></div>
                    <div className="object"></div>
                    <div className="object"></div>
                    <div className="object"></div>
                    <div className="object"></div>
                    <div className="object"></div>
                    <div className="object"></div>
                    <div className="object"></div>
                </div>
            </div>
        </div>
    );
  }

  return (
    <>
        <div>
            <HelmetProvider>
                <Helmet>
                    {/* Head tags for SEO */}
                    <title>Concepcion Concrete | Concrete Experts</title>
                    <meta name="description" content="We are experts in concrete construction. Offering quality services for residential and commercial projects in Nashville, TN." />
                    <meta name="keywords" content="concrete, concrete construction, residential concrete, commercial concrete, Nashville TN" />
                    <meta name="author" content="Concepcion Concrete" />

                    {/* Viewport tag for mobile devices */}
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                    {/* Open Graph tags for social media */}
                    <meta property="og:title" content="Concepcion Concrete | Concrete Experts" />
                    <meta property="og:description" content="We are experts in concrete construction. Offering quality services for residential and commercial projects in Nashville, TN." />
                    <meta property="og:url" content="https://www.concepcionconcrete.com/" />
                    <meta property="og:type" content="website" />
                    <meta name="google-site-verification" content="MydCeMgZ-QaWtSoJTzkcm3hPepxYpJ3HUD6PeObXtoI" />
                    
                    {/* Canonical tag to avoid duplicate content */}
                    <link rel="icon" type="image/x-icon" href="./multimedia/concepcionconcrete.ico" />
                    <link rel="canonical" href="https://www.concepcionconcrete.com/" />
                </Helmet>
            </HelmetProvider>

            <header>
                <div className="top_nav">
                    <div className="container">
                        <ul className="list-inline info row col-12 text-start">
                            <li className="col-md-6 col-sm-12 mt-2"><a href="tel:+16159996064"><FaPhoneAlt className="icon" /> {content.contactForm.phoneText}</a></li>
                            <li className="col-md-6 col-sm-12 mt-2"><a href="mailto:ConcepcionConcrete@hotmail.com"><MdEmail className="icon" /> ConcepcionConcrete@hotmail.com</a></li>
                            <li className="col-md-6 col-sm-12 mt-2">
                                <a href="#"><FaClock className="icon" /> {content.schedule.week} 7:00 - 18:00</a><br/>
                                <a href="#"><FaClock className="icon" /> {content.schedule.weekend} 7:00 - 12:00</a>
                            </li>
                            <li className="col-md-6 col-sm-12 mt-2 row">
                                <div className="col-6"><MdGTranslate /> {content.Language}</div>
                                <div className="col-6">
                                    <select className="form-select" value={language} onChange={handleChangeLanguage}>
                                        <option value="english">English</option>
                                        <option value="spanish">Español</option>
                                    </select>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                    <div className="container-fluid">
                        <a className="navbar-brand text-color" href="#"><img src={logo} className="logo" alt="Logo company" /> Concepcion Concrete</a>
                        <div>
                            <CiMenuKebab className='icon-menu mx-auto' type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"/>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a href=""> {content.nav.home} </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#about"> {content.nav.about} </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#services"> {content.nav.services} </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#contact_form"> {content.nav.contact} </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

            <section id="home" className="home">
                <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                        <img src={slider_img} className="d-block w-100" alt="Driveway" />
                           <div className="overlay">
                                <div className="carousel-caption">
                                    <h4>{content.home.carousel.heading1}</h4>
                                    <h2>{content.home.carousel.heading2}</h2>
                                    <h2 className="second_heading">{content.home.carousel.subheading}</h2>
                                    <p>{content.home.carousel.description}</p>
                                </div>					
                            </div>
                        </div>
                        <div className="carousel-item">
                        <img src={slider_img2} className="d-block w-100" alt="Concrete sidewalk" />
                           <div className="overlay">
                                <div className="carousel-caption">
                                    <h4>{content.home.carousel.heading1}</h4>
                                    <h2>{content.home.carousel.heading2}</h2>
                                    <h2 className="second_heading">{content.home.carousel.subheading}</h2>
                                    <p>{content.home.carousel.description}</p>
                                </div>					
                            </div>
                        </div>
                        <div className="carousel-item">
                        <img src={slider_img3} className="d-block w-100" alt="Patio" />
                           <div className="overlay">
                                <div className="carousel-caption">
                                    <h4>{content.home.carousel.heading1}</h4>
                                    <h2>{content.home.carousel.heading2}</h2>
                                    <h2 className="second_heading">{content.home.carousel.subheading}</h2>
                                    <p>{content.home.carousel.description}</p>
                                </div>					
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">{content.home.carousel.buttonPrev}</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">{content.home.carousel.buttonNext}</span>
                    </button>
                </div>

            </section>

            <section id="about">
                <div className="container about_bg">
                    <div className="row">
                        <div className="col-lg-7 col-md-6">
                            <div className="about_content">
                                <h2>{content.about.heading1}</h2>
                                <h3>{content.about.heading2}</h3>
                                <p>{content.about.description}</p>
                                <a href={content.contactForm.whatsappLink} className="btn know_btn ">{content.about.buttonText}</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-lg-offset-1">
                            <div className="about_banner">
                                <img src={man} alt="man" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="why_us">
                <div className="container text-center">
                    <div className="row">
                        <div className="col-md-offset-2">
                            <div className="head_title">
                                <h2>{content.whyUs.heading}</h2>
                                <p>{content.whyUs.subheading}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-6 mt-4">
                            <div className="why_us_item">
                                <span className="fa">
                                    <GrCertificate className='icons-choose-us' />
                                </span>
                                <h4 className="mt-5 fw-bold">{content.whyUs.items[0].title}</h4>
                                <p>{content.whyUs.items[0].description}</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mt-4">
                            <div className="why_us_item">
                                <span className="fa">
                                    <LuCalendarClock className='icons-choose-us' />
                                </span>
                                <h4 className="mt-5 fw-bold">{content.whyUs.items[1].title}</h4>
                                <p>{content.whyUs.items[1].description}</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mt-4">
                            <div className="why_us_item">
                                <span className="fa">
                                    <FaPeopleGroup className='icons-choose-us' />
                                </span>
                                <h4 className="mt-5 fw-bold">{content.whyUs.items[2].title}</h4>
                                <p>{content.whyUs.items[2].description}</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 mt-4">
                            <div className="why_us_item">
                                <span className="fa">
                                    <MdEngineering className='icons-choose-us' />
                                </span>
                                <h4 className="mt-5 fw-bold">{content.whyUs.items[3].title}</h4>
                                <p>{content.whyUs.items[3].description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="services">
                <div className="container">
                    <h2>{content.services.heading}</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="service_item">
                                <img src={service_img1} alt="Our Services" />
                                <h3>{content.services.items[0].title}</h3>
                                <p>{content.services.items[0].description}</p>
                                <a href={content.contactForm.whatsappLink} className="btn know_btn">{content.about.buttonText}</a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service_item">
                                <img src={service_img2} alt="Our Services" />
                                <h3>{content.services.items[1].title}</h3>
                                <p>{content.services.items[1].description}</p>
                                <a href={content.contactForm.whatsappLink} className="btn know_btn">{content.about.buttonText}</a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="service_item">
                                <img src={service_img3} alt="Our Services" />
                                <h3>{content.services.items[2].title}</h3>
                                <p>{content.services.items[2].description}</p>
                                <a href={content.contactForm.whatsappLink} className="btn know_btn">{content.about.buttonText}</a>
                            </div>
                        </div>
                    </div>
                    <ul className="row">
                        <li className="col-md-4 col-sm-6"><MdFoundation className="services-icons" /> <br/><b>{content.services.features[0]}</b></li>
                        <li className="col-md-4 col-sm-6"><PiStepsDuotone className="services-icons" /> <br/><b>{content.services.features[1]}</b></li>
                        <li className="col-md-4 col-sm-6"><FaRoad className="services-icons" /> <br/><b>{content.services.features[2]}</b></li>
                        <li className="col-md-4 col-sm-6"><FaRoad className="services-icons" /> <br/><b>{content.services.features[3]}</b></li>
                        <li className="col-md-4 col-sm-6"><GiHomeGarage className="services-icons" /> <br/><b>{content.services.features[4]}</b></li>
                        <li className="col-md-4 col-sm-6"><GiBroom className="services-icons" /> <br/><b>{content.services.features[5]}</b></li>
                        <li className="col-md-4 col-sm-6"><FaWater className="services-icons" /> <br/><b>{content.services.features[6]}</b></li>
                        <li className="col-md-4 col-sm-6"><FaStamp className="services-icons" /> <br/><b>{content.services.features[7]}</b></li>
                        <li className="col-md-4 col-sm-6"><GiFloorPolisher className="services-icons" /> <br/><b>{content.services.features[8]}</b></li>
                        <li className="col-md-4 col-sm-6"><PiFingerprintSimpleFill className="services-icons" /> <br/><b>{content.services.features[9]}</b></li>
                        <li className="col-md-4 col-sm-6"><FaWaveSquare  className="services-icons" /> <br/><b>{content.services.features[10]}</b></li>
                        <li className="col-md-4 col-sm-6"><GiBrickWall className="services-icons" /> <br/><b>{content.services.features[11]}</b></li>
                        <li className="col-md-4 col-sm-6"><PiPark className="services-icons" /> <br/><b>{content.services.features[12]}</b></li>
                        <li className="col-md-4 col-sm-6"><PiGarageFill className="services-icons" /> <br/><b>{content.services.features[13]}</b></li>
                        <li className="col-md-4 col-sm-6"><FaSwimmingPool className="services-icons" /> <br/><b>{content.services.features[14]}</b></li>
                    </ul>
                </div>
            </section>

            <section id="portfolio">
                <div className="container portfolio_area text-center">
                    <h2>{content.portfolio.heading}</h2>
                    <p>{content.portfolio.description}</p>
                    <Tabs>
                        <TabList>
                            <Tab>{content.portfolio.categories[0]}</Tab>
                            <Tab>{content.portfolio.categories[1]}</Tab>
                            <Tab>{content.portfolio.categories[2]}</Tab>
                            <Tab>{content.portfolio.categories[3]}</Tab>
                            <Tab>{content.portfolio.categories[4]}</Tab>
                        </TabList>

                        <TabPanel className="row">
                            <img className="col-md-6 col-sm-12 mt-3" alt="Driveway" src={portfolio5} />
                            <img className="col-md-6 col-sm-12 mt-3" alt="Driveway" src={portfolio6} />
                            <img className="col-md-6 col-sm-12 mt-3" alt="Driveway" src={portfolio7} />
                        </TabPanel>
                        <TabPanel className="row">
                            <img className="col-md-6 col-sm-12 mt-3" alt="Steps" src={portfolio10} />
                            <img className="col-md-6 col-sm-12 mt-3" alt="Steps" src={portfolio11} />
                            <img className="col-md-6 col-sm-12 mt-3" alt="Steps" src={portfolio12} />
                        </TabPanel>
                        <TabPanel className="row">
                            <img className="col-md-6 col-sm-12 mt-3" alt="Foundations" src={portfolio1} />
                            <img className="col-md-6 col-sm-12 mt-3" alt="Foundations" src={portfolio2} />
                            <img className="col-md-6 col-sm-12 mt-3" alt="Foundations" src={portfolio3} />
                            <img className="col-md-6 col-sm-12 mt-3" alt="Foundations" src={portfolio4} />
                            <img className="col-md-6 col-sm-12 mt-3" alt="Foundations" src={portfolio16} />
                        </TabPanel>
                        <TabPanel className="row">
                            <img className="col-md-4 col-sm-12 mt-3" alt="Sidewalks" src={portfolio8} />
                            <img className="col-md-4 col-sm-12 mt-3" alt="Sidewalks" src={portfolio9} />
                        </TabPanel>
                        <TabPanel className="row">
                            <img className="col-md-6 col-sm-12 mt-3" alt="Patios" src={portfolio13} />
                            <img className="col-md-6 col-sm-12 mt-3" alt="Patios" src={portfolio14} />
                            <img className="col-md-6 col-sm-12 mt-3" alt="Patios" src={portfolio15} />
                        </TabPanel>
                    </Tabs>
                </div>
            </section>

            <section id="testimonial">
                <div className="container text-center testimonial_area">
                    <h2>{content.testimonial.heading}</h2>
                    <p>{content.testimonial.description}</p>

                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="testimonial_item">
                                <div className="testimonial_content text-left">
                                    <p>{content.testimonial.reviews[0].content}</p>
                                </div>
                                <div className="testimonials mx-auto">
                                    <img src={testimonial1} alt="Testimonial" />
                                </div>
                                <p className="worker_name">John Smith</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="testimonial_item">
                                <div className="testimonial_content">
                                    <p>{content.testimonial.reviews[1].content}</p>
                                </div>
                                <div className="testimonials mx-auto">
                                    <img src={testimonial2} alt="Testimonial" />
                                </div>
                                <p className="worker_name">Chris Doe</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="testimonial_item">
                                <div className="testimonial_content">
                                    <p>{content.testimonial.reviews[2].content}</p>
                                </div>
                                <div className="testimonials mx-auto">
                                    <img src={testimonial3} alt="Testimonial" />
                                </div>
                                <p className="worker_name">Michael Johnson</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact_form">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>{content.contactForm.heading}</h2>
                            <h2 className="second_heading">{content.contactForm.secondHeading}</h2>
                            <p>{content.contactForm.description}</p>
                        </div>
                        <div className="col-md-4 col-sm-12 row">
                            <a className="col-12" href="tel:+16159996064"><FaPhoneAlt className="icon-whatsapp" /> {content.contactForm.phoneText}</a>
                            <a className="col-12" href={content.contactForm.whatsappLink}>
                                <FaWhatsappSquare className="icon-whatsapp" /> {content.contactForm.phoneText}
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <footer>
                <div className="container footer_top">
                    <div className="row">
                        <div className="col-lg-4 col-sm-7">
                            <div className="footer_item">
                                <h4>{content.footer.aboutCompany.heading}</h4>
                                <img src={logo} className="logo" alt="Logo company" /> 
                                <h3 className="nav-item">{content.footer.aboutCompany.companyName}</h3>
                                <p>{content.footer.aboutCompany.description}</p>

                                <ul className="list-inline footer_social_icon row">
                                    <li className="col"><a href="https://www.instagram.com/concepcionconcrete/"><FaSquareInstagram className="icon-contact-us" /></a></li>
                                    {/* <li className="col"><a href="https://www.instagram.com/concepcionconcrete/"><FaFacebookSquare className="icon-contact-us" /></a></li> */}
                                    <li className="col"><a href={content.contactForm.whatsappLink}><FaWhatsappSquare className="icon-contact-us" /></a></li>
                                    <li className="col"><a href="https://www.tiktok.com/@concepcionconcrete?_t=8lv0tMxXaWE&_r=1"><FaTiktok className="icon-contact-us" /></a></li>
                                    <li className="col"><a href="https://www.linkedin.com/company/concepcionconcrete/?viewAsMember=true"><FaLinkedin className="icon-contact-us" /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-5">
                            <div className="footer_item">
                                <h4>{content.footer.aboutCompany.heading}</h4>
                                <ul className="list-unstyled footer_contact">
                                    <li><a href=""><FaLocationDot /> 824 Oakwood Terrace Drive <br/> Antioch, TN 370113</a></li>
                                    <li><a href="mailto:ConcepcionConcrete@hotmail.com"><MdEmail className="icon" /> ConcepcionConcrete@hotmail.com</a></li>
                                    <li><a href="tel:+16159996064"><FaPhoneAlt className="icon" /> {content.contactForm.phoneText}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bottom text-center">
                    <p className="wow fadeInRight">
                        {content.footer.footerBottom.createdBy} <a target="_blank" href="https://www.linkedin.com/in/juan-andres-baron-baron/"><FaCode/></a>. {content.footer.footerBottom.rightsReserved}
                    </p>
                </div>
            </footer>
        </div>	
    </>
  );
}

export default App;
