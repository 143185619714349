const content = {
    Language: "Language",
    schedule: {
        week: "Mon - Fri",
        weekend: "Sat"
    },
    nav: {
      home: " Home ",
      about: " About Us ",
      services: " Services ",
      contact: " Contact Us ",
    },
    home: {
        carousel: {
            heading1: "Your Reliable Concrete Experts",
            heading2: "Cement Solutions",
            subheading: "Quality & Innovation",
            description: "Delivering durable, aesthetic concrete solutions tailored to your needs.",
            buttonPrev: "Previous",
            buttonNext: "Next"
        }
    },
    about: {
        heading1: "Welcome to Our Concrete Experts",
        heading2: "Your Trusted Provider",
        description: "Delivering quality concrete solutions tailored to your needs.",
        buttonText: "Know More"
    },  
    whyUs: {
        heading: "WHY CHOOSE US?",
        subheading: "Experience the difference with our cement services.",
        items: [
            {
                title: "Quality Delivery",
                description: "Reliable, durable, and aesthetically pleasing."
            },
            {
                title: "Timely Completion",
                description: "On-time project completion without compromise."
            },
            {
                title: "Passionate Team",
                description: "Driven by a passion for excellence in every detail."
            },
            {
                title: "Professionalism",
                description: "Expertise and professionalism in every service."
            }
        ]
    },   
    services: {
        heading: "OUR SERVICES",
        items: [
            {
                title: "Concrete Construction Management",
                description: "Efficient project management for successful concrete outcomes."
            },
            {
                title: "Concrete Renovation",
                description: "Transforming concrete spaces with innovative solutions."
            },
            {
                title: "Concrete Architecture",
                description: "Creative architectural designs focusing on concrete aesthetics and functionality."
            }
        ],
        features: [
            "Foundations",
            "Steps",
            "Sidewalks",
            "Concrete Pathways",
            "Driveways",
            "Sweeping Services",
            "Washing Services",
            "Stamping",
            "Polished Floors",
            "Stamped Sweeping",
            "Curves",
            "Walls",
            "Patios",
            "Garages",
            "Pool Sidewalks"
        ]
    },   
    portfolio: {
        heading: "Our Concrete Portfolio",
        description: "Explore our concrete and construction projects showcasing quality craftsmanship.",
        categories: [
            "Driveway",
            "Steps",
            "Foundations",
            "Sidewalks",
            "Patios"
        ],
    },
    testimonial: {
        heading: "Customer Reviews",
        description: "Discover what our clients have to say about our exceptional concrete and construction services.",
        reviews: [
            {
                content: "Outstanding work! The concrete foundation they built for my project is top-notch.",
            },
            {
                content: "Professional and reliable. They renovated my concrete patio beautifully.",
            },
            {
                content: "Excellent service! Their concrete sidewalk work exceeded my expectations.",
            }
        ]
    },
    contactForm: {
        heading: "Have questions or need a quote?",
        secondHeading: "Contact us today!",
        description: "We provide free consultations and estimates.",
        whatsappLink: "https://wa.me/16159996064",
        whatsappText: "615-999-6064",
        phoneLink: "tel:+16159996064",
        phoneText: "615-999-6064",
        email: "ConcepcionConcrete@hotmail.com"
    },    
    footer: {
        aboutCompany: {
            heading: "About Our Company",
            logoAlt: "Concepcion Concrete Logo",
            companyName: "Concepcion Concrete",
            description: "Discover more about our dedicated team and commitment to quality construction services.",
        },
        contactUs: {
            heading: "Contact Us",
            address: "824 Oakwood Terrace Drive, Antioch, TN 370113",
        },
        footerBottom: {
            createdBy: "Made by",
            rightsReserved: "All Rights Reserved"
        }
    },    
  };
  
  export default content;
  