const content = {
    Language: "Idioma",
    schedule: {
        week: "Lun - Vie",
        weekend: "Sáb"
    },
    nav: {
      home: " Inicio ",
      about: " Sobre Nosotros ",
      services: " Servicios ",
      contact: " Contáctanos ",
    },
    home: {
        carousel: {
            heading1: "Sus Expertos Confiables en Concreto",
            heading2: "Soluciones de Cemento",
            subheading: "Calidad e Innovación",
            description: "Entregando soluciones de concreto duraderas y estéticas adaptadas a sus necesidades.",
            buttonPrev: "Anterior",
            buttonNext: "Siguiente"
        }
    }, 
    about: {
        heading1: "Bienvenido somos Expertos en Concreto",
        heading2: "Su Proveedor de Confianza",
        description: "Entregando soluciones de concreto de calidad adaptadas a sus necesidades.",
        buttonText: "Saber más"
    }, 
    whyUs: {
        heading: "¿POR QUÉ ELEGIRNOS?",
        subheading: "Experimenta la diferencia con nuestros servicios de cemento.",
        items: [
            {
                title: "Entrega de Calidad",
                description: "Confiable, duradero y estéticamente atractivo."
            },
            {
                title: "Finalización oportuna",
                description: "Finalización del proyecto a tiempo sin compromisos."
            },
            {
                title: "Equipo Apasionado",
                description: "Impulsados por una pasión por la excelencia en cada detalle."
            },
            {
                title: "Profesionalismo",
                description: "Experiencia y profesionalismo en cada servicio."
            }
        ]
    },
    services: {
        heading: "NUESTROS SERVICIOS",
        items: [
            {
                title: "Gestión de Construcción de Concreto",
                description: "Gestión eficiente de proyectos para resultados exitosos en concreto."
            },
            {
                title: "Renovación de Concreto",
                description: "Transformación de espacios de concreto con soluciones innovadoras."
            },
            {
                title: "Arquitectura de Concreto",
                description: "Diseños arquitectónicos creativos enfocados en la estética y funcionalidad del concreto."
            }
        ],
        features: [
            "Cimientos",
            "Escaleras",
            "Andenes",
            "Caminos de Concreto",
            "Entradas de Vehículos",
            "Servicios de Barrido",
            "Servicios de Lavado",
            "Estampado",
            "Pisos Pulidos",
            "Barrido Estampado",
            "Curvas",
            "Muros",
            "Patios",
            "Garajes",
            "Andenes de Piscina"
        ]
    },
    portfolio: {
        heading: "Nuestro Portafolio de Concreto",
        description: "Explora nuestros proyectos de concreto y construcción que muestran la artesanía de calidad.",
        categories: [
            "Entradas de Vehículos",
            "Escaleras",
            "Cimientos",
            "Andenes",
            "Patios"
        ],
    },  
    testimonial: {
        heading: "Reseñas de Clientes",
        description: "Descubre lo que nuestros clientes tienen que decir sobre nuestros excepcionales servicios de concreto y construcción.",
        reviews: [
            {
                content: "¡Trabajo excepcional! La base de concreto que construyeron para mi proyecto es de primera calidad.",
            },
            {
                content: "Profesionales y confiables. Renovaron mi patio de concreto de manera hermosa.",
            },
            {
                content: "¡Excelente servicio! Su trabajo en la acera de concreto superó mis expectativas.",
            }
        ]
    },
    contactForm: {
        heading: "¿Tienes preguntas o necesitas un presupuesto?",
        secondHeading: "¡Contáctanos hoy mismo!",
        description: "Ofrecemos consultas y presupuestos gratuitos.",
        whatsappLink: "https://wa.me/16159996064",
        whatsappText: "615-999-6064",
        phoneLink: "tel:+16159996064",
        phoneText: "615-999-6064",
        email: "ConcepcionConcrete@hotmail.com"
    },    
    footer: {
        aboutCompany: {
            heading: "Acerca de Nuestra Empresa",
            logoAlt: "Logo de Concepcion Concrete",
            companyName: "Concepcion Concrete",
            description: "Descubre más sobre nuestro equipo dedicado y nuestro compromiso con servicios de construcción de calidad.",
        },
        contactUs: {
            heading: "Contáctenos",
            address: "824 Oakwood Terrace Drive, Antioch, TN 370113",
        },
        footerBottom: {
            createdBy: "Hecho por",
            rightsReserved: "Todos los derechos reservados"
        }
    }
  };
  
  export default content;
  